<template>
    <div class="home" v-if="!isLoading">
        <div class="container">
            <van-nav-bar
                title="请选择您要购买的商品"
                fixed
                placeholder
            />
            <van-empty description="暂无可用商品" v-if="this.sceneInfo.goodsList == undefined || this.sceneInfo.goodsList.length <= 0"/>
            <div class="home-goodslist" v-else>
                <div class="home-goodsitem" v-for="(item,index) in sceneInfo.goodsList" @click="goCart(item.id)"
                     :key="index">
                    <img :src="item.thumb" class="goods-thumb" alt="">
                    <div class="goods-info">
                        <h2 class="van-multi-ellipsis--l2">{{ item.name }}</h2>
                        <div class="goods-price">¥ {{ item.price }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {GET_SCENE_DETAIL} from "@/api/scene"
import {Button, NavBar, Empty} from "vant";

export default {
    name: 'Home',
    components: {
        [Button.name]: Button,
        [NavBar.name]: NavBar,
        [Empty.name]: Empty,
    },
    data() {
        return {
            isLoading: false,
            scene_id: 0,
            sceneInfo: {}
        }
    },
    created() {
        const scene_id = this.$route.query && this.$route.query.scene_id
        if (scene_id) {
            this.scene_id = scene_id
        }
        if (this.scene_id === 0) {
            this.$router.push({ path: '/' })
        }
        localStorage.setItem("sceneId", scene_id + "");
        this.getSceneInfo(scene_id)
    },
    methods: {
        getSceneInfo(scene_id) {
            this.isLoading = true;
            GET_SCENE_DETAIL(scene_id)
                .then(res => {
                    this.isLoading = false;
                    this.checkSccene(res.data);
                })
                .catch(() => {
                    localStorage.removeItem("sceneId");
                    this.$router.push({ path: '/' })
                })
        },
        checkSccene(sceneInfo) {
            let time = parseInt(new Date().getTime() / 1000) + '';
            if(time < sceneInfo.start_time || time > sceneInfo.end_time) {
                localStorage.removeItem("sceneId");
                this.$router.push({ path: '/' })
            } else {
                this.sceneInfo = sceneInfo
            }
        },
        goProduct(e) {
            this.$router.push({
                path: '/product',
                query: {
                    pid: e
                }
            })
        },
        goCart(e) {
            this.$router.push({
                path: '/cart',
                query: {
                pid:e
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.home-header {
    height: 80px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
}

.home-goodslist {
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.home-goodsitem {
    padding: 30px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.goods-thumb {
    width: 150px;
    height: 150px;
    margin-right: 20px;
    flex-shrink: 0;
    flex-grow: 0;
}

.goods-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
}

.goods-info h2 {
    font-size: 28px;
}

.goods-price {
    margin: 20px 0;
    font-size: 36px;
    color: #ff0000;
}
</style>