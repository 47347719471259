import qs from 'qs'
import request from '@/libs/request'

/**
 * 登录接口请求token与userinfo
 * @param params {code: code}
 */
export function loginByCode( data ) {
    return request({
        url: '/user/wechat_auth2',
        method: 'post',
        data
    })
}
/**
 * 获取登录用户信息
 * @param params
 */
export function getUserInfo(params) {
    return request({
        url: '/user/get_user',
        method: 'post',
        data: qs.stringify(params)
    })
}
