<template>
    <div class="home">
        <div class="container">
            <div class="good-item">
                <img :src="product.thumb" class="goods-thumb" alt="">
                <div class="goods-info">
                    <h2 class="van-multi-ellipsis--l2">{{ product.name }}</h2>
                    <p class="goods-desc van-multi-ellipsis--l3">{{ product.description }}</p>
                    <div class="goods-price">¥ {{ product.price }}</div>
                </div>
            </div>
            <div class="gocart">
                <van-button type="primary" round block @click="goCart(product.id)">下单购买</van-button>
            </div>            
        </div>
    </div>
</template>

<script>
import {GET_PRODUCT_INFO} from "@/api/product"
import {NavBar, Field, Button} from "vant";

export default {
    name: 'Home',
    components: {
        [NavBar.name]: NavBar,
        [Field.name]: Field,
        [Button.name]: Button,
    },
    data() {
        return {
            pid: 0,
            product: {}
        }
    },
    created() {
        const pid = this.$route.query && this.$route.query.pid
        if (pid) {
            this.pid = pid
        }
        if (this.pid === 0) {
            this.$router.push({path: '/'})
        }

        let scene_id = localStorage.getItem("sceneId");

        if (!scene_id || scene_id === "0") {
            this.$router.push({ path: '/' })
        }

        console.log("准备请求产品信息，产品ID是" + pid);
        this.getProductInfo(pid)
    },
    methods: {
        goCart(e) {
            this.$router.push({
                path: '/cart',
                query: {
                    pid: e
                }
            })
        },
        getProductInfo(pid) {
            GET_PRODUCT_INFO(pid)
                .then(res => {
                    this.product = res.data
                    console.log(this.product);
                })
        }
    }
}
</script>

<style scoped lang="scss">
.p-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 100px;
    background: #ffffff;
    padding: 20px;
}

.gocart{
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.good-item {
    padding: 30px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.goods-thumb {
    width: 150px;
    height: 150px;
    margin-right: 20px;
    flex-shrink: 0;
    flex-grow: 0;
}

.goods-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
}

.goods-info h2 {
    font-size: 28px;
}

.goods-desc {
    font-size: 24px;
    color: #999;
}

.goods-price {
    margin: 20px 0;
    font-size: 36px;
    color: #ff0000;
}

.goods-content {
    padding: 20px;
    margin-bottom: 100px;
    background: #fff;
    overflow: hidden;
    font-size: 28px;
}

.goods-content p {
    font-size: 28px;
    margin: 0 !important;
    padding: 0 !important;
}

.goods-content img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
</style>
