import request from '@/libs/request'

export function GET_PRODUCT_LIST () {
    return request({
        url: '/product/list',
        method: 'get'
    })
}

export function GET_PRODUCT_INFO (id) {
    return request({
        url: `/product/info/${id}`,
        method: 'get',
    })
}
