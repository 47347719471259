<template>
  <div class="home">
    <div class="container">
      <div class="user-header">
        <img class="avatar" :src="this.$store.getters.userInfo.headimgurl" alt="">
        <p>{{this.$store.getters.userInfo.nickname}}</p>
      </div>
      <van-empty description="您还没有下过单哦" v-if="this.orderlist == undefined || this.orderlist.length <= 0" />
      <div class="home-goodslist" v-else>
        <div class="order-item" v-for="(item,index) in orderlist" :key="index" @click="goOrder(item.order_sn)">
          <van-cell-group>
            <van-cell title="订单编号" value-class="order-item-sn" :value="item.order_sn" />
            <van-cell title="下单时间" :value="item.ctime" />
            <van-cell title="产品名称" :value="item.product_name" />
            <van-cell title="订单金额" :value="'¥ ' + item.amount" />
            <van-cell title="支付状态" v-if="item.status === 0" value-class="pay-status-no" value="待支付" />
            <van-cell title="支付状态" v-if="item.status === 1" value-class="pay-status-yes" value="已支付" />
          </van-cell-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_ORDER_LIST } from "../api/order"
import { Button,Cell,CellGroup,Empty } from "vant";
export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Empty.name]: Empty,
  },
  data () {
    return {
      orderlist: []
    }
  },
  mounted() {
    this.getOrderList()
  },
  methods: {
    getOrderList() {
      GET_ORDER_LIST()
          .then(res => {
            this.orderlist = res.data
          })
    },
    goOrder(order_sn) {
      this.$router.push({
        path: '/order',
        query: {
          order_sn:order_sn
        }
      })
    }
  }
}
</script>

<style scoped>
.user-header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 50px;
  margin-bottom: 20px;
}
.user-header .avatar{
  width: 120px;
  height: 120px;
  border-radius: 60px;
}
.order-item{
  margin-bottom: 20px;
}
.order-item .van-cell__title{
  flex: auto;
  width: 160px;
  flex-shrink: 0;
}
.order-item .van-cell__value{
  flex: auto;
  flex-grow: 1;
}
.order-item-sn{
  font-size: 32px;
}
.pay-status-no{
  color: red;
}
.pay-status-yes{
  color: green;
}
</style>
