<template>
  <div class="home">
    <div class="container">
      <div class="order-info">
        <h3>基本信息</h3>
        <van-cell-group>
          <van-cell title="订单编号" :value="order_sn" />
          <van-cell title="产品名称" :value="orderinfo.product_name" />
          <van-cell title="学校班级" :value="orderinfo.classname" />
          <van-cell title="学生姓名" :value="orderinfo.name" />
          <van-cell title="订单金额" value-class="priceStyle" :value="'¥ ' + orderinfo.amount" />
        </van-cell-group>
        <h3>订单状态</h3>
        <van-cell-group v-if="orderinfo.status === 1">
          <van-cell title="支付状态" value-class="paySuccess" value="已支付" />
          <van-cell title="支付时间" :value="orderinfo.pay_time" />
        </van-cell-group>
        <van-cell-group v-else>
          <van-cell title="支付状态" value="未支付" />
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { GET_ORDER_INFO,PAY_ORDER } from "@/api/order"
import { Button,Cell,CellGroup,Toast } from "vant";
export default {
  name: 'Order',
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
  },
  data () {
    return {
      order_sn: 0,
      orderinfo: {}
    }
  },
  created() {
    const order_sn = this.$route.query && this.$route.query.order_sn
    if (order_sn) {
      this.order_sn = order_sn
    }
    if (this.order_sn === 0) {
      this.$router.push({ path: '/' })
    }
    this.getOrderInfo(order_sn)
  },
  methods: {
    getOrderInfo(order_sn) {
      GET_ORDER_INFO(order_sn)
        .then(res => {
          this.orderinfo = res.data
          if(this.orderinfo.status == 0){
            this.payNow()
          }else if(this.orderinfo.status == 1){
            this.$router.push({
              path: '/paysuccess',
              query: {
                order_sn:this.order_sn
              }
            })
          }
        })
    },
    payNow() {
      var that = this
      PAY_ORDER({order_sn:this.order_sn})
        .then(res => {
          let {
            pay_config,
            jsApiParameter
          } = res.data
          wx.config({
            debug: false,
            appId: pay_config.appId,
            timestamp: pay_config.timestamp,
            nonceStr: pay_config.nonceStr,
            signature: pay_config.signature,
            jsApiList: ['chooseWXPay']
          });
          wx.ready(function() {
            wx.chooseWXPay({
              timestamp: jsApiParameter.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: jsApiParameter.nonceStr, // 支付签名随机串，不长于 32 位
              package: jsApiParameter.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
              signType: jsApiParameter.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: jsApiParameter.paySign, // 支付签名
              success: function() {
                window.location.href="/#/paysuccess?order_sn="+that.order_sn;
              },
              cancel: function() {
                Toast.fail('您已取消支付');
              },
              fail: function() {
                Toast.fail('支付失败');
              }
            });
          });
        })
    }
  }
}
</script>

<style scoped lang="scss">
.order-info .van-cell__title{
  flex: auto;
  width: 160px;
  flex-shrink: 0;
}
.order-info .van-cell__value{
  flex: auto;
  flex-grow: 1;
}
.priceStyle{
  color: red;
  font-size: 36px;
}
.paySuccess{
  color: #07c160;
}
.order-info h3{
  padding: 30px;
  font-size: 28px;
  color: rgba(69, 90, 100, 0.6);
}
</style>
