import request from '@/libs/request'

export function CREATE_ORDER (data) {
    return request({
        url: '/order/create',
        method: 'post',
        data
    })
}

export function GET_ORDER_LIST () {
    return request({
        url: `/order/list`,
        method: 'get',
    })
}

export function GET_ORDER_INFO (order_sn) {
    return request({
        url: `/order/info/${order_sn}`,
        method: 'get',
    })
}

export function PAY_ORDER (data) {
    return request({
        url: '/order/payOrder',
        method: 'post',
        data
    })
}
