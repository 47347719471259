<template>
  <div class="home">
    <div class="container">
      <van-nav-bar
          title="请选择您要购买的商品"
          fixed
          placeholder
      />
      <van-empty description="暂无可用商品" />
    </div>
  </div>
</template>

<script>
import { Button,NavBar,Empty } from "vant";
export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Empty.name]: Empty,
  },
  data () {
    return {
      scene_id: 0,
    }
  },
  mounted() {
    this.scene_id = localStorage.getItem("sceneId");
    if(this.scene_id !== "0" && this.scene_id !== null) {
        this.goScene(this.scene_id);
    }
  },
  methods: {
    goScene(e) {
        this.$router.push({
            path: '/scene',
            query: {
                scene_id:e
            }
        })
    },
  }
}
</script>

<style scoped lang="scss">
.home-header{
  height: 80px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}
.home-goodslist{
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.home-goodsitem{
  padding: 30px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.goods-thumb{
  width: 150px;
  height: 150px;
  margin-right: 20px;
  flex-shrink: 0;
  flex-grow: 0;
}
.goods-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}
.goods-info h2{
  font-size: 28px;
}
.goods-price{
  margin: 20px 0;
  font-size: 36px;
  color: #ff0000;
}
</style>
