import request from '@/libs/request'

export function GET_SCHOOL_LIST () {
    return request({
        url: '/school/list',
        method: 'get'
    })
}

export function GET_SCENE_SCHOOL_LIST (id) {
    return request({
        url: `/school/scene_list/${id}`,
        method: 'get'
    })
}
